import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { ResponsiveModalEntity } from "./modal-container.types";

@Injectable({
	providedIn: "root",
})
export abstract class ResponsiveModalContainerService<T> {
	abstract closeDialog(): void;
	abstract getDialogData(): unknown;
	abstract openDialog(component: ComponentType<T>, data?: unknown, config?: unknown): void;
	abstract reloadData(data: unknown): void;

	protected dialogQueue = new Array<ResponsiveModalEntity<T>>();
}
