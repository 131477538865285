<cp-transparent-card>
	<swiper-container #swiperRef [config]="config" eTenantHubSwiper init="false">
		@for (slide of slides; track slide.template; let index = $index) {
			<swiper-slide>
				<cp-translucid-card class="not">
					<ng-container [ngTemplateOutlet]="slide.template"></ng-container>
				</cp-translucid-card>
			</swiper-slide>
		}
	</swiper-container>
</cp-transparent-card>
