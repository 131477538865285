import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { StampedIconComponent } from "./stamped-icon.component";

@NgModule({
	imports: [CommonModule, CommonIconModule],
	declarations: [StampedIconComponent],
	exports: [StampedIconComponent],
})
export class StampedIconModule {}
