import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { FormControl, ValidatorFn, Validators } from "@angular/forms";
import { BaseControlInputComponent } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/base-control-input";
import { Subscription } from "rxjs";

@Component({
	selector: "cp-file-input",
	templateUrl: "./file-input.component.html",
	styleUrl: "./file-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent extends BaseControlInputComponent implements OnInit, OnDestroy {
	@Input() maxSize = 5;
	@Input() acceptedMimeTypes = "application/pdf,image/png,image/jpeg,image/png,image/gif,image/tiff";

	@Output() selectedFile!: File;

	@ViewChild("fileSelector", { static: false }) fileSelector!: ElementRef;

	hasFocus = false;
	isRequired = true;
	fileSelectorFormControl: FormControl = new FormControl("");
	private fileSelectionSub!: Subscription;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.trackFileSelection();
		this.isRequired = this.ngControl.control.hasValidator(Validators.required);
		this.manageValidator();
	}

	ngOnDestroy(): void {
		this.fileSelectionSub?.unsubscribe();
	}

	onFocus() {
		this.hasFocus = true;
	}

	onBlur() {
		this.hasFocus = false;
	}

	clearFileSelection() {
		this.fileSelectorFormControl.patchValue("");
		this.ngControl.control.patchValue("");
	}

	manageValidator() {
		if (this.isRequired) this.ngControl.control.addValidators(this.requiredFileValidator());
		this.ngControl.control.addValidators([this.fileSizeValidator(), this.fileTypeValidator()]);
		this.ngControl.control.updateValueAndValidity();
	}

	trackFileSelection() {
		this.fileSelectionSub = this.fileSelectorFormControl.valueChanges?.subscribe(() => {
			this.selectedFile = this.fileSelector.nativeElement.files[0];
			this.ngControl.control.patchValue(this.selectedFile ? `${this.selectedFile?.name}` : "");
		}) as Subscription;
	}

	//#region Validation
	private fileSizeValidator(): ValidatorFn {
		return () => {
			if (this.maxSize && !this.validateFileSize(this.selectedFile)) return { fileSize: true };
			return null;
		};
	}

	private requiredFileValidator(): ValidatorFn {
		return () => {
			if (this.isRequired && !this.selectedFile) return { required: true };
			return null;
		};
	}

	private fileTypeValidator(): ValidatorFn {
		return () => {
			if (this.acceptedMimeTypes && !this.validateFileMimeType(this.selectedFile)) return { fileType: true };
			return null;
		};
	}

	private validateFileSize(file: File): boolean {
		if (!file) return true;
		// 1 048 576 = 1 024 x 1 024
		return file.size <= this.maxSize * 1048576;
	}

	private validateFileMimeType(file: File): boolean {
		if (!file) return true;
		return this.acceptedMimeTypes.split(",").findIndex((e) => e.toLowerCase() === file.type) !== -1;
	}

	//#endregion
}
