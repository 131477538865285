import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SwiperDirective } from "./swiper.directive";

@NgModule({
	imports: [CommonModule],
	declarations: [SwiperDirective],
	exports: [SwiperDirective],
})
export class SwiperModule {}
