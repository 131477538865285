import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { RadioInputOption } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/radio-input";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { AuthService } from "@e-tenant-hub/shared/auth";
import {
	NotificationType,
	PreferenceType,
	ThirdPartiesService,
	ThirdPartyPreference,
	ThirdPartyPreferencesRequest,
} from "@e-tenant-hub/shared/rentals";
import { Observable, Subscription, catchError, of, tap } from "rxjs";

@Component({
	selector: "cp-third-party-preferences-modal",
	templateUrl: "./third-party-preferences-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThirdPartyPreferencesModalComponent implements OnInit, OnDestroy {
	private readonly formBuilder = inject(FormBuilder);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly notificationsService = inject(NotificationsService);
	private readonly thirdPartiesService = inject(ThirdPartiesService);
	private readonly authService = inject(AuthService);
	private readonly user = this.authService.user;

	form!: FormGroup;
	favoriteContactModesRadioOptions: RadioInputOption[] = this.createFavoriteContactModesRadioInputOptions();
	isComponentLoading = true;
	isComponentSubmitting = false;
	noticeDeliveryModesRadioOptions: RadioInputOption[] = this.createNoticeDeliveryModesRadioInputOptions();

	// Subscriptions
	loadSubscription!: Subscription;
	submitSubscription!: Subscription;

	getThirdPartyPreferences$: Observable<ThirdPartyPreference[]> = this.thirdPartiesService.getPreferences(
		this.user.thirdPartyId
	);

	constructor() {
		this.createForm();
	}

	ngOnInit(): void {
		this.loadData();
	}

	ngOnDestroy(): void {
		this.loadSubscription?.unsubscribe();
		this.submitSubscription?.unsubscribe();
	}

	clearFormStatus(): void {
		this.form.markAsUntouched();
		this.form.markAsPristine();
	}

	createForm() {
		this.form = this.formBuilder.group({
			favoriteContactMode: [""],
			noticeDeliveryMode: [""],
		});
	}

	private createFavoriteContactModesRadioInputOptions(): RadioInputOption[] {
		return [
			{
				label: "Par SMS",
				value: NotificationType.sms,
			} as RadioInputOption,
			{
				label: "Par mail",
				value: NotificationType.email,
			} as RadioInputOption,
		];
	}

	private createNoticeDeliveryModesRadioInputOptions(): RadioInputOption[] {
		return [
			{
				label: "Par courrier",
				value: NotificationType.post,
			} as RadioInputOption,
			{
				label: "Via l'Espace Locataire",
				value: NotificationType.tenantSpace,
			} as RadioInputOption,
		];
	}

	private loadData() {
		this.loadSubscription = this.getThirdPartyPreferences$
			.pipe(
				catchError(() => {
					this.notificationsService.showErrorMessage("Erreur lors de la récupération de vos préférences");
					return of(undefined);
				}),
				tap((thirdPartyPreferences) => {
					this.isComponentLoading = false;
					if (thirdPartyPreferences) {
						this.form.patchValue({
							favoriteContactMode: thirdPartyPreferences.find(
								(e) => e.type === PreferenceType.favoriteContactMode
							)?.value,
							noticeDeliveryMode: thirdPartyPreferences.find(
								(e) => e.type === PreferenceType.noticeDelivery
							)?.value,
						});
					}
				})
			)
			.subscribe();
	}

	submit() {
		if (!this.form.valid) return;

		const updateRequest: ThirdPartyPreferencesRequest = {
			items: new Array<ThirdPartyPreference>(),
		};

		if (this.form.controls["favoriteContactMode"].dirty && this.form.value.favoriteContactMode) {
			updateRequest.items.push({
				type: PreferenceType.favoriteContactMode,
				value: this.form.value.favoriteContactMode,
			});
		}

		if (this.form.controls["noticeDeliveryMode"].dirty && this.form.value.noticeDeliveryMode) {
			updateRequest.items.push({
				type: PreferenceType.noticeDelivery,
				value: this.form.value.noticeDeliveryMode,
			});
		}

		if (updateRequest.items.length === 0) return;

		this.isComponentSubmitting = true;
		this.submitSubscription = this.thirdPartiesService
			.updatePreferences(this.user.thirdPartyId, updateRequest)
			.pipe(
				catchError(() => {
					this.isComponentSubmitting = false;
					this.notificationsService.showErrorMessage(
						"Le changement de vos préférences a échoué, veuillez réessayer ultérieurement."
					);
					return of(undefined);
				})
			)
			.subscribe((response) => {
				this.isComponentSubmitting = false;
				if (response) {
					if (response.result) {
						this.clearFormStatus();
						this.notificationsService.showConfirmationMessage("Vos préférences ont bien été modifiées.");
						this.modalContainerService.closeDialog();
					} else
						this.notificationsService.showErrorMessage(
							"Le changement de vos préférences a échoué, veuillez réessayer ultérieurement."
						);
				}
			});
	}
}
