<cp-common-modal-container [title]="'Demande de prélèvement'">
	<div class="inline-flex w-full flex-col items-center justify-center gap-1 rounded-lg bg-gradient-to-r px-3 py-2">
		<div class="h-25 flex flex-col items-center justify-center gap-4 self-stretch">
			<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
				Pour effectuer une demande de prélèvement, veuillez télécharger le document ci-dessous, l'imprimer, le
				remplir et nous l'envoyer à l'adresse suivante :
			</div>
			<div
				class="font-futura self-stretch rounded-lg border border-zinc-200 px-3 py-2 text-base font-medium text-neutral-800"
			>
				Est Métropole Habitat <br />
				55 rue de la Soie 69602 Villeurbanne
			</div>
			<div>
				<cp-rounded-button
					[label]="'formulaire de demande sepa'"
					[icon]="'download'"
					(click)="downloadSEPAForm()"
				>
				</cp-rounded-button>
			</div>
		</div>
	</div>
</cp-common-modal-container>
