<div class="inline-flex w-full flex-col items-start justify-start gap-3">
	<div class="inline-flex w-full flex-col items-start justify-start gap-1">
		@if (label) {
			<label
				class="font-futura text-xs font-medium text-neutral-400 lg:text-sm"
				[class.invalid]="ngControl.invalid && ngControl.touched"
				[class.focused]="hasFocus"
			>
				{{ label }}
			</label>
		}
		<mat-form-field
			[class.solid]="fill === 'solid'"
			[class.translucid]="fill === 'translucid'"
			[class.empty]="fill === 'empty'"
			[class.dashed]="fill === 'dashed'"
			appearance="outline"
			subscriptSizing="dynamic"
		>
			<input
				class="!font-futura !py-2 !text-sm !font-medium !text-zinc-600 sm:!py-3 lg:!text-base"
				#fileDisplay
				[class.cursor-pointer]="'pointer'"
				[placeholder]="placeholderOrLabel"
				[formControl]="ngControl.control"
				(focus)="onFocus()"
				(blur)="onBlur()"
				(click)="fileSelector.click()"
				type="text"
				readonly
				matInput
			/>
			<cp-common-icon
				class="text-primary text-2xl"
				[name]="fileDisplay.value ? 'clear' : 'download'"
				[isFilled]="true"
				[class.cursor-pointer]="'pointer'"
				(click)="fileDisplay.value ? clearFileSelection() : fileSelector.click()"
				matSuffix
			></cp-common-icon>
			<input
				#fileSelector
				[accept]="acceptedMimeTypes"
				[formControl]="fileSelectorFormControl"
				type="file"
				hidden
			/>
			<mat-error class="font-futura pt-2 text-xs font-medium text-rose-500 lg:text-sm">
				@if (ngControl.control.hasError("required")) {
					<span class="inline-flex">La sélection d'un document est obligatoire.</span>
				}
				@if (ngControl.hasError("fileType")) {
					<span class="inline-flex">Ce type de fichier n'est pas pris en charge.</span>
				}
				@if (ngControl.hasError("fileSize")) {
					<span class="inline-flex">Le fichier sélectionné est trop volumineux.</span>
				}
			</mat-error>
		</mat-form-field>
	</div>
	<div class="font-futura text-xs font-medium text-neutral-400 lg:text-sm">
		Taille : 5Mo maximum<br />
		Formats acceptés : png, jpg, jpeg, pdf, gif, tif
	</div>
</div>
