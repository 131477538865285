import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { RoundedButtonModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/buttons/rounded-button";
import { ChipModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/chip";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { CommonAutoCompleteInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/common-auto-complete-input";
import { CommonInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/common-input";
import { DatePickerInputsModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/date-picker-inputs";
import { SelectInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/select-input";
import { TextareaInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/textarea-input";
import { ToggleInputModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/toggle-input";
import { SearchSolicitationsFormComponent } from "./search-solicitations-form/search-solicitations-form.component";

@NgModule({
	imports: [
		ChipModule,
		CommonModule,
		CommonInputModule,
		CommonIconModule,
		CommonAutoCompleteInputModule,
		RoundedButtonModule,
		DatePickerInputsModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		SelectInputModule,
		TextareaInputModule,
		ToggleInputModule,
	],
	declarations: [SearchSolicitationsFormComponent],
	exports: [SearchSolicitationsFormComponent],
})
export class ClientPlatformFormsModule {}
