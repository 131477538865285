import { ChangeDetectionStrategy, Component, OnInit, ViewChild, inject } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { CarouselComponent } from "@e-tenant-hub/client-platform/shared/ui/mobile/carousel";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";
import { AuthService } from "@e-tenant-hub/shared/auth";
import {
	FormResponse,
	FormsService,
	PropositionReponse,
	QuestionAnswer,
	QuestionAnswersRequest,
	QuestionType,
} from "@e-tenant-hub/shared/rentals";
import { CommonValidators } from "@e-tenant-hub/shared/utils";
import { SwiperOptions } from "swiper/types";

@Component({
	selector: "cp-questions-form-modal",
	templateUrl: "./questions-form-modal.component.html",
	styleUrl: "./questions-form-modal.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsFormModalComponent implements OnInit {
	@ViewChild("carouselCpt") carousel?: CarouselComponent;

	private readonly formBuilder = inject(FormBuilder);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly authService = inject(AuthService);
	private readonly formsService = inject(FormsService);

	readonly user = this.authService.user;

	currentQuestionIndex = 0;
	data: FormResponse;
	form!: FormGroup;
	isLastCarouselSlide = false;
	questionTypes = QuestionType;
	showStepper = false;

	swiperOptions: SwiperOptions = {
		autoHeight: true,
		pagination: true,
		noSwiping: true,
		noSwipingSelector: "*",
		injectStyles: [
			`
			:host .swiper {
			  overflow: initial;
			}
			:host .swiper-pagination {
				bottom: -1.5rem;
			}
			:host .swiper-pagination .swiper-pagination-bullet {
				width: 0.5rem;
			}
			:host .swiper-backface-hidden ::slotted(swiper-slide.swiper-slide-prev) {
				transform:translateX(-1.5rem);
			}
			:host .swiper-backface-hidden ::slotted(swiper-slide.swiper-slide-next) {
				transform:translateX(1.5rem);
			}
			`,
		],
	};

	constructor() {
		this.data = this.modalContainerService.getDialogData() as FormResponse;
		this.data.questions.sort((a, b) => a.order - b.order);
		this.createForm();
	}

	ngOnInit() {
		this.form.statusChanges.subscribe();
	}

	isEndOfQuestions(): boolean {
		return this.carousel?.isEnd || this.carousel?.activeIndex == this.carousel?.length - 2;
	}

	isFirstQuestion(): boolean {
		return !this.carousel?.activeIndex || this.carousel?.activeIndex === 0;
	}

	createForm() {
		const group: { [key: string]: any } = {};

		this.data.questions.forEach((question) => {
			if (question.type === QuestionType.SingleChoice) {
				group[question.id] = this.formBuilder.group({
					answer: ["", Validators.required],
				});
			}

			if (question.type === QuestionType.FreeAnswer) {
				group[question.id] = this.formBuilder.group({
					description: question.description,
					answer: ["", Validators.required],
				});
			}

			if (question.type === QuestionType.MultipleChoice) {
				group[question.id] = this.formBuilder.group({
					answers: this.formBuilder.array(
						question.propositions.map((prop: PropositionReponse) => {
							return this.formBuilder.group({
								label: [prop.value, Validators.required],
								checked: [false, Validators.required],
							});
						}),
						CommonValidators.minSelectedCheckboxes(1)
					),
				});
			}
		});

		this.form = this.formBuilder.group(group);
	}

	submit() {
		if (this.form.invalid) return;

		const formAnswers: QuestionAnswer[] = [];

		Object.keys(this.form.controls).forEach((key) => {
			const question = this.form.controls[key];
			question.markAsDirty();

			if (question) {
				const singleAnswer = question.get("answer");
				const multipleAnswers = question.get("answers");

				if (singleAnswer instanceof FormControl) {
					formAnswers.push({
						questionId: key,
						answer: singleAnswer.value,
					});
				}

				if (multipleAnswers instanceof FormArray) {
					multipleAnswers.controls
						.filter((element) => element.get("checked")?.value)
						.forEach((element, _) => {
							formAnswers.push({
								questionId: key,
								answer: element.get("label")?.value,
							});
						});
				}
			}
		});

		if (!formAnswers || formAnswers.length === 0) {
			return;
		}

		const request: QuestionAnswersRequest = {
			answers: formAnswers,
		};

		this.formsService.registerQuestionAnswers(this.user.thirdPartyId, request).subscribe({
			next: () => {
				this.carousel?.slideNext();
			},
		});

		this.isLastCarouselSlide = true;
	}

	getFormGroupById(id: string): FormGroup {
		return this.form.get(id) as FormGroup;
	}

	closeDialog(): void {
		this.modalContainerService.closeDialog();
	}

	isCurrentQuestionAnswered(): boolean {
		const currentQuestionId = this.data.questions[this.currentQuestionIndex]?.id;
		const control = this.form.get(currentQuestionId);

		return control ? control.valid : false;
	}

	nextQuestion(): void {
		if (this.isCurrentQuestionAnswered()) {
			this.carousel?.slideNext();
			this.currentQuestionIndex = this.currentQuestionIndex + 1;
		}
	}

	lastQuestion(): void {
		this.carousel?.slidePrev();
		this.currentQuestionIndex = this.currentQuestionIndex - 1;
	}
}
