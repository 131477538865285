import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { TranslucidCardModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/cards/translucid-card";
import { TransparentCardModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/cards/transparent-card";
import { SwiperModule } from "@e-tenant-hub/shared/directives/swiper";
import { CarouselSlideDirective } from "./carousel-slide/carousel-slide.directive";
import { CarouselComponent } from "./carousel.component";

@NgModule({
	imports: [CommonModule, SwiperModule, TranslucidCardModule, TransparentCardModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [CarouselComponent, CarouselSlideDirective],
	exports: [CarouselComponent, CarouselSlideDirective],
})
export class CarouselModule {}
