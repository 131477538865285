import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { ResponsiveModalContainerService } from "../modal-container.service";

@Component({
	selector: "cp-complex-modal-container",
	templateUrl: "./complex-modal-container.component.html",
	styleUrl: "./complex-modal-container.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplexModalContainerComponent {
	@Input() title?: string;
	@Input() description?: string;
	@Input() hasReminderButton = false; // TODO: Création de bouton "dynamique"
	@Input() hasCancellationButton = false; // TODO: Création de bouton "dynamique"

	@Output() readonly reminderButtonClicked = new EventEmitter<void>(); // TODO: Création de bouton "dynamique"
	@Output() readonly cancellationButtonClicked = new EventEmitter<void>(); // TODO: Création de bouton "dynamique"

	private readonly modalService = inject(ResponsiveModalContainerService);

	handleReminderButtonClick(): void {
		if (this.hasReminderButton) {
			this.reminderButtonClicked.emit();
		}
	}

	handleCancellationButtonClick(): void {
		if (this.hasCancellationButton) {
			this.cancellationButtonClicked.emit();
		}
	}

	closeDialog(): void {
		this.modalService.closeDialog();
	}
}
