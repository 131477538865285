import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";
import { FinancialTransactionResponse } from "@e-tenant-hub/shared/rentals";

@Component({
	selector: "cp-transaction-detail-modal",
	templateUrl: "./transaction-detail-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionDetailModalComponent {
	data: FinancialTransactionResponse;

	private readonly modalContainerService = inject(ResponsiveModalContainerService);

	constructor() {
		this.data = this.modalContainerService.getDialogData() as FinancialTransactionResponse;
	}
}
