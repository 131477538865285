import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { MaskitoDirective } from "@maskito/angular";
import { CommonInputComponent } from "./common-input.component";

@NgModule({
	imports: [CommonModule, ReactiveFormsModule, MatInputModule, CommonIconModule, MaskitoDirective],
	declarations: [CommonInputComponent],
	exports: [CommonInputComponent],
})
export class CommonInputModule {}
