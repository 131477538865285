import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { UtilsModule } from "@e-tenant-hub/shared/utils";
import { FileInputComponent } from "./file-input.component";

@NgModule({
	imports: [CommonIconModule, CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, UtilsModule],
	declarations: [FileInputComponent],
	exports: [FileInputComponent],
})
export class FileInputModule {}
