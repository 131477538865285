<cp-common-modal-container
	[title]="isSolicitationCreated ? 'demande prise en compte' : 'nouvelle demande'"
	[showCloseButton]="!isSolicitationCreated"
	[imgSrc]="isSolicitationCreated ? '/assets/images/demande-formulaire.svg' : undefined"
	[confirmButtonLabel]="isSolicitationCreated ? 'j\'ai compris' : undefined"
	(confirmButtonClicked)="closeModal()"
>
	@if (!isSolicitationCreated) {
		<form class="flex w-full flex-col gap-6" [formGroup]="solicitationCreationForm" (ngSubmit)="submit()">
			<div class="flex flex-col gap-3">
				<cp-select-input
					[options]="categorySelectOptions"
					[multiple]="false"
					[resetOptionLabel]="'Tous'"
					[placeholder]="'Catégorie'"
					[fill]="'empty'"
					formControlName="category"
				></cp-select-input>
				<cp-select-input
					[options]="subCategorySelectOptions[selectedCategoryIndex]"
					[multiple]="false"
					[placeholder]="'Objet'"
					[fill]="'empty'"
					formControlName="subCategory"
				></cp-select-input>
			</div>
			@if (category.value.value === solicitationCategories.technical) {
				<div class="flex flex-col">
					<cp-radio-input
						[label]="'Ma demande concerne...'"
						[options]="scopeRadioOptions"
						formControlName="scope"
					></cp-radio-input>
				</div>
			}
			<div class="flex flex-col">
				<cp-textarea-input
					[placeholder]="'Message libre...'"
					[fill]="'empty'"
					[maxLength]="commentMaxLength"
					formControlName="comment"
				></cp-textarea-input>
			</div>
			<div class="font-futura text-xs text-zinc-600">
				Suite à la saisie de votre demande, si vous avez des documents à nous transmettre, merci de passer par
				<a class="text-primary font-medium" href="mailto:crc@est-metropole-habitat.fr"
					>crc&#64;est-metropole-habitat.fr</a
				>
				en indiquant votre numéro de locataire et l'objet de la demande en cours.
			</div>
			<cp-rounded-button
				[label]="'Créer ma demande'"
				[icon]="'done'"
				[type]="'submit'"
				[isFullWidth]="true"
				[disabled]="solicitationCreationForm.invalid || isFormSubmitDisabled"
			></cp-rounded-button>
		</form>
	} @else {
		<div class="flex flex-col items-center justify-center gap-3 self-stretch">
			<div class="inline-flex items-center justify-center gap-6 self-stretch">
				<cp-common-icon
					class="text-primary relative text-2xl"
					[name]="'done'"
					[isFilled]="false"
				></cp-common-icon>
				<div class="font-futura shrink grow basis-0 text-base font-medium text-neutral-400">
					Tout message à caractère insultant, irrespectueux ou menaçant pourra être modifié ou supprimé.
				</div>
			</div>
			<div class="inline-flex items-center justify-center gap-6 self-stretch">
				<cp-common-icon
					class="text-primary relative text-2xl"
					[name]="'done'"
					[isFilled]="false"
				></cp-common-icon>
				<div class="font-futura shrink grow basis-0 text-base font-medium text-neutral-400">
					Votre demande pourra être requalifiée par votre Centre de Relation Clientèle.
				</div>
			</div>
			<div class="inline-flex items-center justify-center gap-6 self-stretch">
				<cp-common-icon
					class="text-primary relative text-2xl"
					[name]="'done'"
					[isFilled]="false"
				></cp-common-icon>
				<div class="font-futura shrink grow basis-0 text-base font-medium text-neutral-400">
					Nos équipes reprendront contact avec vous dans les meilleurs délais.
				</div>
			</div>
		</div>
	}
</cp-common-modal-container>
