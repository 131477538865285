import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { ResponsiveModalContainerService } from "../modal-container.service";

@Component({
	selector: "cp-common-modal-container",
	templateUrl: "./common-modal-container.component.html",
	styleUrl: "./common-modal-container.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonModalContainerComponent {
	@Input() title?: string;
	@Input() imgSrc?: string;
	@Input() cancelButtonLabel?: string;
	@Input() cancelButtonDisabled = false;
	@Input() confirmButtonLabel?: string;
	@Input() confirmButtonDisabled = false;
	@Input() showCloseButton = true;

	@Output() readonly confirmButtonClicked = new EventEmitter<void>();

	private modalService = inject(ResponsiveModalContainerService);

	closeDialog(): void {
		this.modalService.closeDialog();
	}

	handleConfirmButtonClick(): void {
		this.confirmButtonClicked.emit();
	}
}
