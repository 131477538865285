<div class="flex-col gap-2">
	<div class="inline-flex items-center justify-center gap-2">
		@if (icon) {
			@if (data.type === "stampedIcon") {
				<cp-stamped-icon
					[mainIcon]="icon"
					[stampedIcon]="
						data.isStampedIconValidated === undefined
							? undefined
							: data.isStampedIconValidated
								? 'check_circle'
								: 'canceled'
					"
				>
				</cp-stamped-icon>
			} @else {
				<cp-common-icon
					class="relative text-xl"
					[ngClass]="{
						'text-primary': data.type === 'information',
						'text-success': data.type === 'confirmation',
						'text-error': data.type === 'error'
					}"
					[name]="icon"
					[isFilled]="true"
				></cp-common-icon>
			}
		}
		<div class="font-futura text-sm font-medium leading-6 text-zinc-600 sm:text-base">{{ data.message }}</div>
		@if (data.action && data.actionLabel) {
			<button class="ps-3" (click)="data.action()" matRipple>
				<div class="font-futura text-base font-medium text-pink-600">{{ data.actionLabel }}</div>
			</button>
		}
	</div>
</div>
