<div class="flex w-full flex-col items-center justify-center gap-6">
	<div class="flex w-full flex-col items-center justify-center gap-3">
		<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
			L'espace locataire d'Est Métropole Habitat utilise des cookies afin de faciliter l'utilisation du site,
			assurer son bon fonctionnement et réaliser des statistiques de fréquentation et de navigation du site.
		</div>
		<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
			Ces cookies nécessitent votre accord pour être déposés sur votre terminal. Nous conservons votre choix
			pendant 6 mois.
		</div>
		<div class="font-futura self-stretch text-sm font-medium text-neutral-800">
			Vous pouvez changer d'avis à tout moment en cliquant sur
			<span class="font-bold uppercase">Gérer les cookies</span> situé en bas de page.
		</div>
	</div>
	<div class="flex w-full flex-col items-center justify-center gap-3">
		<cp-rounded-button class="w-full" [label]="'Personnaliser'" (click)="btnSettingsClick()"></cp-rounded-button>

		<div class="inline-flex w-full gap-3">
			<cp-rounded-button
				class="w-1/2"
				[isReversed]="true"
				[label]="'Tout refuser'"
				(click)="btnRefuseAllClick()"
			></cp-rounded-button>
			<cp-rounded-button
				class="w-1/2"
				[label]="'Tout accepter'"
				(click)="btnAcceptAllClick()"
			></cp-rounded-button>
		</div>
		<a
			class="text-primary font-futura cursor-pointer text-sm font-medium underline"
			href="/cookies-policy"
			target="_blank"
			>Données personnelles et cookies</a
		>
	</div>
</div>
