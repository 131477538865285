import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FileInputComponent } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/file-input";
import { SelectInputOption } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/select-input";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";
import { NotificationsService } from "@e-tenant-hub/client-platform/shared/ui/responsive/notifications";
import { AuthService } from "@e-tenant-hub/shared/auth";
import {
	CommunicationService,
	FileProgress,
	InsuranceStatus,
	InsuranceStatusItem,
	InsurancesService,
	RentalLotTypes,
	UpdateInsuranceRequest,
} from "@e-tenant-hub/shared/rentals";
import { DatesValidators, PreciseDate } from "@e-tenant-hub/shared/utils";
import { Observable, Subscription, catchError, map, of } from "rxjs";

@Component({
	selector: "cp-insurance-form-modal",
	templateUrl: "./insurance-form-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuranceFormModalComponent implements OnDestroy {
	@ViewChild("fileInput") fileInput?: FileInputComponent;

	private readonly formBuilder = inject(FormBuilder);
	private readonly communicationService = inject(CommunicationService);
	private readonly insurancesService = inject(InsurancesService);
	private readonly modalContainerService = inject(ResponsiveModalContainerService);
	private readonly notificationsService = inject(NotificationsService);
	private readonly authService = inject(AuthService);
	private readonly user = this.authService.user;

	form!: FormGroup;
	datePickerMinDate = new PreciseDate().subtract(1, "years");
	datePickerMaxDate = new PreciseDate().add(2, "years");
	isComponentSubmitting = false;
	fileProgress!: FileProgress;

	// Subscriptions
	private endDateValueChangeSubscription!: Subscription;
	private submitSubscription!: Subscription;
	private uploadDocumentSubscription!: Subscription;

	getInsuranceStatusByRental$: Observable<SelectInputOption[]> = this.insurancesService
		.getInsuranceStatusByRental(this.user.currentRentalId)
		.pipe(
			map((response: InsuranceStatus) =>
				response.items.map(
					(e: InsuranceStatusItem) =>
						<SelectInputOption>{
							label:
								RentalLotTypes.record[e.typeCode] +
								(e.endDate !== null ? " - " + new PreciseDate(e.endDate).format("DD.MM.YYYY") : ""),
							value: e.unitId,
						}
				)
			)
		);

	constructor() {
		this.createForm();
	}

	ngOnDestroy(): void {
		this.endDateValueChangeSubscription?.unsubscribe();
		this.submitSubscription?.unsubscribe();
		this.uploadDocumentSubscription?.unsubscribe();
	}

	clearFormStatus(): void {
		this.form.markAsUntouched();
		this.form.markAsPristine();
	}

	createForm() {
		this.form = this.formBuilder.group({
			lotIds: ["", Validators.required],
			insuranceFile: ["", Validators.required],
			startDate: ["", Validators.required],
			endDate: ["", [Validators.required, DatesValidators.greaterThanValidator("startDate")]],
		});

		this.endDateValueChangeSubscription = this.form.controls["startDate"].valueChanges.subscribe(() => {
			this.form.controls["endDate"].updateValueAndValidity();
		});
	}

	submit() {
		if (!this.form.valid) return;

		const updateRequest = {
			lotIdentifiers: this.form.value.lotIds.map((e: SelectInputOption) => e.value),
			beginDate: this.form.value.startDate,
			endDate: this.form.value.endDate,
			file: this.fileInput?.selectedFile,
		} as UpdateInsuranceRequest;

		this.isComponentSubmitting = true;
		this.submitSubscription = this.insurancesService
			.updateInsuranceDocument(this.user.thirdPartyId, this.user.currentRentalId, updateRequest)
			.pipe(
				catchError(() => {
					this.isComponentSubmitting = false;
					this.notificationsService.showErrorMessage(
						"Le chargement de votre attestation d'assurance a échoué, veuillez réessayer ultérieurement."
					);
					return of(undefined);
				})
			)
			.subscribe((response: any) => {
				if (response && response.ok) {
					this.isComponentSubmitting = false;
					this.clearFormStatus();
					this.notificationsService.showConfirmationMessage(
						"Votre attestation d'assurance a bien été enregistrée."
					);
					this.communicationService.insuranceUploaded();
					this.modalContainerService.closeDialog();
				}
			});
	}
}
