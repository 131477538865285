<cp-common-modal-container [title]="data.isTransactionSuccess ? 'Paiement réussi' : 'Échec de paiement'">
	<div class="relative w-full overflow-auto rounded-2xl p-4">
		<cp-common-background class="sm:!z-0" [withMoreOpacity]="true"></cp-common-background>
		<div class="relative inline-flex w-full flex-col items-center justify-center gap-2 sm:!z-[1]">
			<cp-common-icon
				class="text-primary rounded-full bg-white p-3 text-2xl"
				[name]="data.isTransactionSuccess ? 'credit_score' : 'credit_card_off'"
				[isFilled]="true"
			></cp-common-icon>
			<div class="flex flex-col items-center justify-center gap-1 self-stretch">
				<div class="font-futura text-center text-base font-medium text-neutral-800">
					{{ data.amount | currency: "EUR" : "symbol" : "1.2-2" : "fr-fr" }}
				</div>
				<div class="font-futura self-stretch text-center text-sm font-medium text-zinc-600">
					Votre solde {{ data.isTransactionSuccess ? "a" : "n'a pas" }} été mis à jour
				</div>
			</div>
		</div>
	</div>
</cp-common-modal-container>
