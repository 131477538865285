import { ChangeDetectionStrategy, Component, ViewChild, inject } from "@angular/core";
import { CarouselComponent } from "@e-tenant-hub/client-platform/shared/ui/mobile/carousel";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";
import { ThirdPartySurveySetting } from "@e-tenant-hub/shared/rentals";

@Component({
	selector: "cp-announcement-modal",
	templateUrl: "./announcement-modal.component.html",
	styleUrl: "./announcement-modal.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementModalComponent {
	@ViewChild("carouselCpt") carousel?: CarouselComponent;

	private readonly modalContainerService = inject(ResponsiveModalContainerService);

	data: ThirdPartySurveySetting[];

	announcements: ThirdPartySurveySetting[];
	currentTitle: string;

	constructor() {
		this.data = this.modalContainerService.getDialogData() as ThirdPartySurveySetting[];
		this.announcements = Array.isArray(this.data) ? this.data : [this.data];
		this.currentTitle = this.data[0].title;
	}

	handleCarouselSlideChange(idx: number) {
		this.currentTitle = this.announcements[idx].title;
	}
}
