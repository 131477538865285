import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { ExpansionPanelComponent } from "./expansion-panel.component";

@NgModule({
	imports: [CommonModule, MatExpansionModule, CommonIconModule],
	declarations: [ExpansionPanelComponent],
	exports: [ExpansionPanelComponent],
})
export class ExpansionPanelModule {}
