import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";
import { register } from "swiper/element/bundle";
import { SwiperOptions } from "swiper/types";

@Directive({
	selector: "[eTenantHubSwiper]",
})
export class SwiperDirective implements AfterViewInit {
	@Input() config?: SwiperOptions;

	private readonly swiperElement: HTMLElement;

	constructor(el: ElementRef<HTMLElement>) {
		this.swiperElement = el.nativeElement;
	}

	ngAfterViewInit() {
		register();

		Object.assign(this.swiperElement, this.config);
		// @ts-expect-error - Ignore this because there is no initialize method on the HTMLElement
		this.swiperElement.initialize();
	}
}
