import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BaseControlInputComponent } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/base-control-input";
import { Subscription } from "rxjs";

@Component({
	selector: "cp-toggle-input",
	templateUrl: "./toggle-input.component.html",
	styleUrl: "./toggle-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleInputComponent extends BaseControlInputComponent implements OnInit, OnDestroy {
	@Input() selectedLabel = "";
	@Input() unselectedLabel = "";
	@Input() isDisabled = false;

	isChecked = false;
	hasFocus = false;

	private valueChangeSubscription!: Subscription;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.isChecked = this.ngControl.value as boolean;

		if (this.isDisabled) this.ngControl.control.disable();

		this.valueChangeSubscription = this.ngControl.control.valueChanges?.subscribe(() => {
			this.isChecked = this.ngControl.value as boolean;
		}) as Subscription;
	}

	ngOnDestroy(): void {
		this.valueChangeSubscription?.unsubscribe();
	}

	onFocus() {
		this.hasFocus = true;
	}

	onBlur() {
		this.hasFocus = false;
	}
}
