import { Injectable } from "@angular/core";
import { DateAdapter, MatDateFormats } from "@angular/material/core";
import { PreciseDate } from "@e-tenant-hub/shared/utils";
import dayjs from "dayjs";

@Injectable()
export class PreciseDateAdapter extends DateAdapter<PreciseDate> {
	constructor() {
		super();
	}

	override getYear(date: PreciseDate): number {
		return date.year();
	}
	override getMonth(date: PreciseDate): number {
		return date.month();
	}
	override getDate(date: PreciseDate): number {
		return date.date();
	}
	override getDayOfWeek(date: PreciseDate): number {
		return date.day();
	}
	override getMonthNames(style: "long" | "short" | "narrow"): string[] {
		return style === "long" ? PreciseDate.longMonthNames() : PreciseDate.shortMonthNames();
	}
	override getDateNames(): string[] {
		return PreciseDate.dateNames();
	}
	override getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
		switch (style) {
			case "long":
				return PreciseDate.longWeekdayNames();
			case "short":
				return PreciseDate.shortWeekdayNames();
			default:
				return PreciseDate.narrowWeekdayNames();
		}
	}
	override getYearName(date: PreciseDate): string {
		return date.yearName();
	}
	override getFirstDayOfWeek(): number {
		return PreciseDate.firstDayOfWeek();
	}
	override getNumDaysInMonth(date: PreciseDate): number {
		return date.daysInMonth();
	}
	override clone(date: PreciseDate): PreciseDate {
		// TODO: Not good
		return date;
	}
	override createDate(year: number, month: number, date: number): PreciseDate {
		return new PreciseDate().createDate(year, month, date);
	}
	override today(): PreciseDate {
		return PreciseDate.current();
	}
	override parse(value: dayjs.ConfigType, parseFormat: string): PreciseDate | null {
		if (value && typeof value === "string") {
			return new PreciseDate(value, PreciseDate.longDateFormat(parseFormat), this.locale);
		}
		return value ? new PreciseDate(value).locale(this.locale) : null;
	}
	override format(date: PreciseDate, displayFormat: any): string {
		return date.format(displayFormat);
	}
	override addCalendarYears(date: PreciseDate, years: number): PreciseDate {
		return date.add(years, "years");
	}
	override addCalendarMonths(date: PreciseDate, months: number): PreciseDate {
		return date.add(months, "months");
	}
	override addCalendarDays(date: PreciseDate, days: number): PreciseDate {
		return date.add(days, "days");
	}
	override toIso8601(date: PreciseDate): string {
		return date.toISOString();
	}
	override isDateInstance(obj: any): boolean {
		return obj instanceof PreciseDate;
	}
	override isValid(date: PreciseDate): boolean {
		return date.isValid();
	}
	override invalid(): PreciseDate {
		return new PreciseDate(null);
	}
}

export const PRECISE_DATE_FORMATS: MatDateFormats = {
	parse: {
		dateInput: "DD/MM/YYYY", // Format de date à parser
	},
	display: {
		dateInput: "DD/MM/YYYY", // Format d'affichage de la date
		monthYearLabel: "MMMM YYYY", // Format pour le label mois et année
		dateA11yLabel: "DD/MM/YYYY", // Format pour l'accessibilité de la date
		monthYearA11yLabel: "MMMM YYYY", // Format pour l'accessibilité mois et année
	},
};
