<div class="inline-flex w-full flex-col items-start justify-start gap-1">
	@if (label) {
		<label
			class="font-futura text-xs font-medium text-neutral-400 lg:text-sm"
			[class.invalid]="datesForm.invalid && datesForm.touched"
			[class.focused]="hasFocus"
		>
			{{ label }}
		</label>
	}
	<mat-form-field
		[class.solid]="fill === 'solid'"
		[class.translucid]="fill === 'translucid'"
		[class.empty]="fill === 'empty'"
		appearance="outline"
		subscriptSizing="dynamic"
	>
		<mat-date-range-input
			class="!font-futura !text-sm !font-medium !text-zinc-600 lg:!text-base"
			[formGroup]="datesForm"
			[rangePicker]="picker"
			[min]="minDate"
			[max]="maxDate"
		>
			<input matInput matStartDate formControlName="startDate" placeholder="Date de début" />
			<input matInput matEndDate formControlName="endDate" placeholder="Date de fin" />
		</mat-date-range-input>
		<mat-datepicker-toggle [for]="picker" matIconSuffix>
			<cp-common-icon [name]="'calendar_today'" matDatepickerToggleIcon></cp-common-icon>
		</mat-datepicker-toggle>
		<mat-date-range-picker #picker [touchUi]="isSmallScreen"></mat-date-range-picker>
		<mat-error class="font-futura pt-2 text-xs font-medium text-rose-500 lg:text-sm">
			<!-- Generic errors -->
			@if (datesForm.controls["startDate"].hasError("matStartDateInvalid")) {
				<span class="inline-flex">La date de début n'est pas valide.</span>
			}
			<!-- Specific 'dates' errors -->
			@if (datesForm.controls["endDate"].hasError("matEndDateInvalid")) {
				<span class="inline-flex">La date de fin doit être supérieure à la date de début.</span>
			}
		</mat-error>
	</mat-form-field>
</div>
