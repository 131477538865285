import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { BaseControlInputComponent } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/base-control-input";
import { SelectInputOption } from "./select-input-option/select-input-option.type";

@Component({
	selector: "cp-select-input",
	templateUrl: "./select-input.component.html",
	styleUrl: "./select-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectInputComponent extends BaseControlInputComponent {
	@Input() multiple = true;
	@Input() options: SelectInputOption[] = [];
	@Input() resetOptionLabel?: string;

	@Output() readonly selectionChanged = new EventEmitter<MatSelectChange>();

	@ViewChild(MatSelect) matSelect?: MatSelect;

	hasFocus = false;

	isPanelOpen = false;
	isPanelOpenTop = false;

	onFocus() {
		this.hasFocus = true;
	}

	onBlur() {
		this.hasFocus = false;
	}

	detectPanelOpening(isPanelOpen: boolean) {
		this.isPanelOpen = isPanelOpen;
		this.determinePanelPositionOpening();
	}

	private determinePanelPositionOpening() {
		if (!this.matSelect?.panelOpen) return;

		// Get MatSelect position in relation to the top of the window
		const matSelectRect = this.matSelect._elementRef.nativeElement.getBoundingClientRect();
		// Get panel position in relation to the top of the window
		const panelRect = this.matSelect.panel.nativeElement.getBoundingClientRect();

		// Determine panel position
		this.isPanelOpenTop = panelRect.top < matSelectRect.bottom;
	}

	emitSelectionChange($event: MatSelectChange) {
		this.selectionChanged.emit($event);
	}
}
