import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";
import { OnlinePaymentModalData } from "./online-payment-modal.type";

@Component({
	selector: "cp-payment-modal",
	templateUrl: "./online-payment-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnlinePaymentModalComponent {
	private readonly modalContainerService = inject(ResponsiveModalContainerService);

	data: OnlinePaymentModalData;

	constructor() {
		this.data = this.modalContainerService.getDialogData() as OnlinePaymentModalData;
	}
}
