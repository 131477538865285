import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { ChangeDetectionStrategy, Component, Input, NgZone, OnInit, ViewChild, inject } from "@angular/core";
import { BaseControlInputComponent } from "@e-tenant-hub/client-platform/shared/ui/responsive/inputs/base-control-input";
import { take } from "rxjs/internal/operators/take";

@Component({
	selector: "cp-textarea-input",
	templateUrl: "./textarea-input.component.html",
	styleUrl: "./textarea-input.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaInputComponent extends BaseControlInputComponent implements OnInit {
	@Input() maxLength?: number;
	@Input() minRows = 3;
	@Input() maxRows = 5;
	@Input() displayHintType: "always" | "maxLengthReached" | "never" = "always";

	@ViewChild("autosize") autosize!: CdkTextareaAutosize;

	private readonly ngZone = inject(NgZone);

	get showHint(): boolean {
		const hasMaxLength = this.maxLength !== undefined;
		switch (this.displayHintType) {
			case "always":
				return hasMaxLength;
			case "maxLengthReached":
				return hasMaxLength && this.ngControl.value?.length === this.maxLength;
			default:
				return false;
		}
	}

	hasFocus = false;

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.triggerResize();
	}

	triggerResize() {
		// Wait for changes to be applied, then trigger textarea resize.
		this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
	}

	onFocus() {
		this.hasFocus = true;
	}
}
