<div class="inline-flex w-full flex-col items-end justify-center gap-6">
	@if (imgSrc) {
		<div class="place-self-center">
			<img class="shrink grow basis-0 self-stretch" [src]="imgSrc" />
		</div>
	}
	<div class="inline-flex w-full items-center justify-center gap-4">
		@if (showCloseButton) {
			<div class="h-6 w-6"></div>
		}
		@if (title) {
			<div class="font-intro shrink grow basis-0 text-center text-lg font-bold text-neutral-800">
				{{ title | uppercase }}
			</div>
		}
		@if (showCloseButton) {
			<button class="rounded-full p-1 hover:bg-zinc-600/5" (click)="closeDialog()">
				<cp-common-icon class="text-2xl text-zinc-600" [name]="'close'"></cp-common-icon>
			</button>
		}
	</div>
	<ng-content></ng-content>
	@if (cancelButtonLabel || confirmButtonLabel) {
		<div class="inline-flex w-full items-center justify-center gap-3">
			@if (cancelButtonLabel) {
				<cp-rounded-button
					[class]="confirmButtonLabel ? 'w-1/2' : 'w-full'"
					[label]="cancelButtonLabel"
					[disabled]="cancelButtonDisabled"
					[isReversed]="true"
					(click)="closeDialog()"
				></cp-rounded-button>
			}
			@if (confirmButtonLabel) {
				<cp-rounded-button
					[class]="cancelButtonLabel ? 'w-1/2' : 'w-full'"
					[label]="confirmButtonLabel"
					[disabled]="confirmButtonDisabled"
					(click)="handleConfirmButtonClick()"
				></cp-rounded-button>
			}
		</div>
	}
</div>
