import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CommonIconModule } from "@e-tenant-hub/client-platform/shared/ui/responsive/common-icon";
import { DatePickerInputComponent } from "./date-picker-input";
import { DatePickerRangeInputComponent } from "./date-picker-range-input/date-picker-range-input.component";

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		CommonIconModule,
	],
	declarations: [DatePickerInputComponent, DatePickerRangeInputComponent],
	exports: [DatePickerInputComponent, DatePickerRangeInputComponent],
})
export class DatePickerInputsModule {}
