import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "cp-link-button",
	templateUrl: "./link-button.component.html",
	styleUrl: "./link-button.component.scss",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkButtonComponent {
	@Input({ required: true }) label!: string;
	@Input() icon?: string;
	@Input() disabled = false;
	@Output() readonly clicked = new EventEmitter<void>();

	handleClick(): void {
		this.clicked.emit();
	}
}
