import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ResponsiveModalContainerService } from "@e-tenant-hub/client-platform/shared/ui/responsive/modal-container";

@Component({
	selector: "cp-solicitation-search-form-modal",
	templateUrl: "./solicitation-search-form-modal.component.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolicitationSearchFormModalComponent {
	private readonly modalContainerService = inject(ResponsiveModalContainerService);

	closeModal(): void {
		this.modalContainerService.closeDialog();
	}
}
