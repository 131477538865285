<div class="flex items-end justify-start">
	<div class="bullet relative flex h-8 w-8 items-center justify-center gap-2.5 rounded-full">
		<div class="to-primary absolute inset-0 h-8 w-8 rounded-full bg-gradient-to-t from-amber-600 opacity-25"></div>
		<div class="absolute inset-0 h-8 w-8 rounded-full bg-gradient-to-r from-rose-200 to-85%"></div>
		<cp-common-icon
			class="text-primary absolute text-xl"
			[name]="mainIcon"
			[isFilled]="mainIconIsFilled"
		></cp-common-icon>
	</div>
	@if (stampedIcon) {
		<cp-common-icon
			class="text-primary relative -left-2 h-4 w-4"
			[name]="stampedIcon"
			[isFilled]="true"
		></cp-common-icon>
	}
</div>
