<mat-expansion-panel #panel hideToggle>
	<mat-expansion-panel-header class="!h-auto !p-0">
		<mat-panel-title
			class="font-futura !mr-2 !shrink-0 !grow-0 !whitespace-nowrap text-sm font-medium !text-zinc-600"
		>
			{{ title }}
		</mat-panel-title>
		<mat-panel-description>
			<cp-common-icon
				class="text-[19px] text-zinc-600"
				[name]="panel.expanded ? 'expand_less' : 'expand_more'"
			></cp-common-icon>
		</mat-panel-description>
	</mat-expansion-panel-header>
	<div>
		<ng-content></ng-content>
	</div>
</mat-expansion-panel>
